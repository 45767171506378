// ==================================================
//  メインビジュアルのアニメーション
// ==================================================
window.addEventListener("load", (event) => {
  const jsLoadedAnimationElement = document.querySelector(".mainvisual-js");
  const loadedAnimation = () => {
    jsLoadedAnimationElement.classList.add("mainvisual-js-active");
  };
  setTimeout(loadedAnimation, 1.0 * 600);
});

// ==================================================
//  パララックス（Simple Parallax）
// ==================================================
const parallaxImage = document.getElementsByClassName("parallaxImage-js");
new simpleParallax(parallaxImage, {
  scale: 1.2,
  delay: 0.45,
  transition: "cubic-bezier(0, 0, 0, .1)",
});

// ==================================================
//  ヘッダーの追従
// ==================================================
window.addEventListener("load", (event) => {
  const header = document.querySelector(".fixedScroll-js");
  const options = {
    fixClass: "fixedScroll-js-fix",
    showClass: "fixedScroll-js-show",
    showDelay: 50,
  };

  if (!header) {
    return false;
  }

  options.startPosi = 0;
  options.setTimeoutId = 0;

  window.addEventListener("resize", function () {
    headerFix(options);
  });
  window.addEventListener("scroll", function () {
    headerFix(options);
  });

  function headerFix(options) {
    const headerBasePosi = 0;
    const headerFixPosi = 718;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    const fixClass = options.fixClass;
    const showClass = options.showClass;

    if (scrollTop > options.startPosi) {
      clearTimeout(options.setTimeoutId);

      if (scrollTop > headerFixPosi) {
        header.classList.add(fixClass);
        options.setTimeoutId = setTimeout(function () {
          headerShow(showClass);
        }, options.showDelay);
      }
    } else {
      if (scrollTop <= headerBasePosi) {
        header.classList.remove(fixClass, showClass);
      }
    }

    options.startPosi = scrollTop;
  }

  function headerShow(showClass) {
    header.classList.add(showClass);
  }
});

// ==================================================
//  追従要素の横スクロール連動
// ==================================================
const fixedScroll = document.querySelector(".fixedScroll-js");
window.addEventListener("scroll", () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  if (scrollTop > 0 || is_mobile()) {
    fixedScroll.style.left = -window.pageXOffset + "px";
  }
});
